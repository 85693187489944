import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import { useAdvertisementsCount } from 'components/page/homePage/hooks/totalCount/useAdvertisementsTotalCount';

const AdvertisementCountDisplay: FC = () => {
    const { count, isLoading } = useAdvertisementsCount();

    const lemmatizedString = lemmatizeWordAccordingToNumber(count, {
        eqZero: 'nehnuteľností',
        eqOne: 'nehnuteľnosť',
        betweenTwoAndFourInclusive: 'nehnuteľnosti',
        moreThenFour: 'nehnuteľností',
    });

    const [formattedNumber, text] = lemmatizedString.split(' ', 2);

    return (
        <>
            {isLoading && (
                <Box
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.identityLightGray.main,
                        borderRadius: 2,
                    }}
                >
                    <Skeleton
                        sx={{
                            width: {
                                xs: 280,
                                sm: 320,
                            },
                            height: {
                                xs: 28,
                            },
                        }}
                    />
                </Box>
            )}
            {count && count > 0 ? (
                <Box>
                    <Text
                        center
                        semibold
                        variant="h5"
                        mobileVariant="body2"
                        color={(palette) => palette.labelPrimaryInverse}
                    >
                        Ponuka&nbsp;
                        <Box
                            sx={{
                                background:
                                    'linear-gradient(91deg, #E40046 -0.01%, #CE0F69 100.02%)',
                                borderRadius: 2,
                                padding: {
                                    xs: '0 6px',
                                    sm: '2px 8px',
                                },
                                display: 'inline-block',
                            }}
                        >
                            {formattedNumber}
                        </Box>
                        &nbsp;{text}
                    </Text>
                </Box>
            ) : undefined}
        </>
    );
};

export default AdvertisementCountDisplay;
