import { ESubCategory as EApiSubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { ESubCategory } from 'components/page/homePage/enums/categorySefs/ESubCategory';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import GarageIcon from 'modules/theme/components/icons/category/Garage.svg';
import OfficesIcon from 'modules/theme/components/icons/category/Offices.svg';
import StoreIcon from 'modules/theme/components/icons/category/Store.svg';
import RestaurantIcon from 'modules/theme/components/icons/category/Restaurant.svg';
import WarehouseIcon from 'modules/theme/components/icons/category/Warehouse.svg';
import FactoryIcon from 'modules/theme/components/icons/category/Factory.svg';
import OtherObjectsIcon from 'modules/theme/components/icons/category/OtherObjects.svg';
import HotelIcon from 'modules/theme/components/icons/category/Hotel.svg';
import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';
import { createUrlWithQueryParams } from 'modules/advertisementsFilter/query/creators/createUrlWithQueryParamsForHP';

export const spacesAndObjectsIconBoxes = [
    {
        label: ESubCategory.SECONDARY_GARAGE_AND_PARKING,
        icon: GarageIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_GARAGE_AND_PARKING),
        sef: ESefCategories.SECONDARY_GARAGE_AND_PARKING,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
        icon: OfficesIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION),
        sef: ESefCategories.SECONDARY_OFFICES_AND_ADMINISTRATION,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_THE_SHOP,
        icon: StoreIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_THE_SHOP),
        sef: ESefCategories.SECONDARY_THE_SHOP,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_A_RESTAURANT,
        icon: RestaurantIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_A_RESTAURANT),
        sef: ESefCategories.SECONDARY_A_RESTAURANT,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_WAREHOUSES,
        icon: WarehouseIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_WAREHOUSES),
        sef: ESefCategories.SECONDARY_WAREHOUSES,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_PRODUCTION,
        icon: FactoryIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_PRODUCTION),
        sef: ESefCategories.SECONDARY_PRODUCTION,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_HOTEL_GUESTHOUSE,
        icon: HotelIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.HOTEL_AND_PENSION}`,
        sef: ESefCategories.HOTEL_AND_PENSION,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
    {
        label: ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
        icon: OtherObjectsIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT),
        sef: ESefCategories.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
        parentSef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
];