import { useRouter } from 'next/navigation';
import useDialog from 'modules/dialog/useDialog';
import { Theme, useMediaQuery } from '@mui/material';
import { Modal } from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/modal/Modal';
import { spacesAndObjectsIconBoxes } from 'components/page/homePage/constants/config/whatAreYouLookingFor/iconBoxes/spacesAndObjects';
import { createUrlWithQueryParams } from 'modules/advertisementsFilter/query/creators/createUrlWithQueryParamsForHP';
import { ECategory } from 'components/page/homePage/enums/categorySefs/ECategory';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

interface IReturn {
    show: () => void;
    component: JSX.Element | null;
}

export const useObjectsAndSpacesDialog = (): IReturn => {
    const router = useRouter();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const {
        component: modal,
        show,
        hide,
    } = useDialog({
        content: (
            <Modal
                title={ECategory.SECONDARY_SPACES_AND_OBJECTS}
                onClose={() => hide()}
                items={spacesAndObjectsIconBoxes}
                onTitleClick={() => router.push(createUrlWithQueryParams(EMainCategory.SECONDARY_SPACES_AND_OBJECTS, true))}
            />
        ),
        paperProps: {
            sx: {
                borderRadius: (theme) => ({
                    xs: theme.spacing(2, 2, 0, 0),
                    md: theme.spacing(2),
                }),
                width: {
                    xs: '100%',
                    md: '400px'
                }
            },
        },
        dialogContentProps: {
            sx: {
                padding: {
                    xs: 3,
                    md: 4,
                }
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
        keepMounted: true,
        disablePortal: true,
    });

    return {
        show,
        component: modal
    };
};
