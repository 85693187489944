import { useRouter } from 'next/navigation';
import useDialog from 'modules/dialog/useDialog';
import { Theme, useMediaQuery } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import {
    Modal
} from 'components/page/homePage/components/whatAreYouLookingFor/partials/iconsBoxes/partials/iconBox/partials/modal/Modal';
import { landsIconBoxes } from 'components/page/homePage/constants/config/whatAreYouLookingFor/iconBoxes/lands';
import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';
import { ECategory } from 'components/page/homePage/enums/categorySefs/ECategory';

interface IReturn {
    show: () => void;
    component: JSX.Element | null;
}

export const useLandsDialog = (): IReturn => {
    const router = useRouter();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const {
        component: modal,
        show,
        hide,
    } = useDialog({
        content: (
            <Modal
                title={ECategory.SECONDARY_LANDS}
                onClose={() => hide()}
                items={landsIconBoxes}
                onTitleClick={() => router.push(`${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.LANDS}`)}
            />
        ),
        paperProps: {
            sx: {
                borderRadius: (theme) => ({
                    xs: theme.spacing(2, 2, 0, 0),
                    md: theme.spacing(2),
                }),
                width: {
                    xs: '100%',
                    md: '400px',
                }
            },
        },
        dialogContentProps: {
            sx: {
                padding: {
                    xs: 3,
                    md: 4,
                }
            },
        },
        wrapContentIntoBox: false,
        fullScreen: isMobile,
        fullWidth: isMobile,
        heightFitContent: isMobile,
        positionBottom: isMobile,
        keepMounted: true,
        disablePortal: true,
    });

    return {
        show,
        component: modal
    };
};
