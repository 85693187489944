import { IIconBox } from 'components/page/homePage/interfaces/whatAreYouLookingFor/IIconBox';

import ApartmentIcon from 'components/page/homePage/assets/icons/Apartment.svg';
import HouseIcon from 'components/page/homePage/assets/icons/House.svg';
import CottageIcon from 'components/page/homePage/assets/icons/Cottage.svg';
import LandIcon from 'components/page/homePage/assets/icons/Land.svg';
import ObjectsIcon from 'components/page/homePage/assets/icons/Objects.svg';

import { ECategory } from 'components/page/homePage/enums/categorySefs/ECategory';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';

export const iconBoxes: IIconBox[] = [
    {
        label: ECategory.APARTMENTS,
        icon: ApartmentIcon,
        mainLink: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.APARTMENTS}`,
        sef: ESefCategories.APARTMENTS,
    },
    {
        label: ECategory.SECONDARY_HOUSES,
        icon: HouseIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_HOUSES}`,
        sef: ESefCategories.SECONDARY_HOUSES,
    },
    {
        label: ECategory.SECONDARY_RECREATIONAL_PROPERTIES,
        icon: CottageIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.RECREATIONAL_HOUSES}`,
        sef: ESefCategories.RECREATIONAL_HOUSES,
    },
    {
        label: ECategory.SECONDARY_LANDS,
        icon: LandIcon,
        mainLink: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_LANDS}`,
        sef: ESefCategories.SECONDARY_LANDS,
    },
    {
        label: ECategory.SECONDARY_SPACES_AND_OBJECTS,
        icon: ObjectsIcon,
        mainLink: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_SPACES_AND_OBJECTS}`,
        sef: ESefCategories.SECONDARY_SPACES_AND_OBJECTS,
    },
];