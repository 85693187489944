import { IPopularRegion } from 'components/page/homePage/components/popularRegions/common/interfaces/IPopularRegion';
import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import Link from 'components/link/Link';
import { Box, Stack, useTheme } from '@mui/material';
import useAnalytics from 'modules/state/app/hook/analytics/useAnalytics';
import { useRecoilState } from 'recoil';
import { appUserIsLoggedIn } from 'modules/state/app/state';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { getClickOnPopularRegionsSKEvent } from 'modules/gtmEvents/helpers/events/homePage/homePageEventsHelper';

interface IProps {
    item: IPopularRegion;
}

export const PopularRegionItem: FC<IProps> = ({ item }) => {
    const theme = useTheme();

    const {
        gtm: { sendEvent: gtmSendEvent },
    } = useAnalytics();

    const [isAdvertiserLoggedIn] = useRecoilState(appUserIsLoggedIn);

    return (
        <Link
            href={`${AppConfigService.getNehnutelnostiUrl()}/${item.sef}`}
            noUnderline
            onClick={() => {
                gtmSendEvent(
                    getClickOnPopularRegionsSKEvent(item.sef, isAdvertiserLoggedIn)
                );
            }}
        >
            <Stack direction={'row'} gap={1.5} alignItems={'center'}>
                <Box
                    width={40}
                    height={32}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    border={`1px solid ${theme.palette.dividerTransparent}`}
                    borderRadius={0.5}
                >
                    <Text
                        variant={'body2'}
                        semibold
                        color={(palette) => palette.labelSecondary}
                    >
                        {item.abbreviation}
                    </Text>
                </Box>
                <Text noWrap variant={'body2'} underLine>
                    {item.linkText}
                </Text>
            </Stack>
        </Link>
    );
};
