'use client';

import { FC } from 'react';
import dynamic from 'next/dynamic';
import { Theme, useMediaQuery, Container } from '@mui/material';
import { Filter } from 'components/page/homePage/components/filter/Filter';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import { DevProjects } from 'components/page/homePage/components/devProjects/DevProjects';
import { BecomeMember } from 'components/page/homePage/components/becomeMember/BecomeMember';
import { PopularRegions } from 'components/page/homePage/components/popularRegions/PopularRegions';
import { IHomePageDevProject } from 'modules/api/clients/homePage/interfaces/devProjects/IHomePageDevProject';
import { WhatAreYouLookingFor } from 'components/page/homePage/components/whatAreYouLookingFor/WhatAreYouLookingFor';

import {
    IInitialSearchFilterData
} from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { Mobile } from 'modules/theme/components/responsive';

const Ads = dynamic(() => import('./ads/Ads').then((component) => component.Ads), { ssr: false });

interface IProps {
    baseFilterLocations: ILocation[];
    devProjectsInitial: IHomePageDevProject[];
    initialSearchFilterData: IInitialSearchFilterData;
}

export const Main: FC<IProps> = ({
    devProjectsInitial,
    baseFilterLocations,
    initialSearchFilterData,
}) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    return (
        <Container maxWidth={false} disableGutters={true}>
            <Container maxWidth={'xl'}>
                <Filter
                    baseFilterLocations={baseFilterLocations}
                    initialSearchFilterData={initialSearchFilterData}
                />
            </Container>

            <Container disableGutters={isMobile}>
                <BecomeMember />
            </Container>

            <Container>
                <Mobile>
                    <Ads />
                </Mobile>
            </Container>

            <Container maxWidth={'xl'} disableGutters={isMobile}>
                <DevProjects devProjectsInitial={devProjectsInitial}/>
            </Container>

            <Container>
                <WhatAreYouLookingFor />
            </Container>

            <Container>
                <PopularRegions />
            </Container>
        </Container>
    );
};