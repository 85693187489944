import { AdvertisementListing } from 'modules/api/clients/advertisement/listing/AdvertisementsListing';

export const fetchAdvertisementsCount = async (): Promise<number> => {
    try {
        return await new AdvertisementListing().getAdvertisementsTotalCount({});
    } catch (error) {
        throw new Error(
            `Failed to fetch advertisement total count. Error: ${error.message}`
        );
    }
};
