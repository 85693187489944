import { useState, useEffect } from 'react';
import { fetchAdvertisementsCount } from 'components/page/homePage/api/fetchAdvertisementsTotalCount';

interface UseAdvertisementsTotalCountReturn {
    count: number;
    isLoading: boolean;
    error: string | null;
}

export const useAdvertisementsCount = (): UseAdvertisementsTotalCountReturn => {
    const [count, setCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const totalCount = await fetchAdvertisementsCount();
                const roundedCount = Math.floor(totalCount / 1000) * 1000;
                setCount(roundedCount);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCount();
    }, []);

    return { count, isLoading, error };
};
