import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { newCategoryIdToOldNskId } from 'modules/advertisementsFilter/query/constants/categories/newCategoryIdToOldNskId';

export const categoriesToExcludeFromSefOnly: (EMainCategory | ESubCategory)[]  = [
    // lands
    ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES,
    ESubCategory.SECONDARY_COMMERCIAL_PLOTS,
    ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
    ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,

    // spaces and objects
    EMainCategory.SECONDARY_SPACES_AND_OBJECTS,
    ESubCategory.SECONDARY_GARAGE_AND_PARKING,
    ESubCategory.SECONDARY_OFFICES_AND_ADMINISTRATION,
    ESubCategory.SECONDARY_THE_SHOP,
    ESubCategory.SECONDARY_WAREHOUSES,
    ESubCategory.SECONDARY_A_RESTAURANT,
    ESubCategory.SECONDARY_PRODUCTION,
    ESubCategory.SECONDARY_ANOTHER_SPACE_AND_OBJECT,
];

// @TODO - remove when new listing is out
export const mapQueryParamCategoryToOld = (
    categoriesFromFilter?: (EMainCategory | ESubCategory)[],
): string[] => {
    const oldNskCategories: string[] = [];

    if (categoriesFromFilter && categoriesFromFilter.length) {
        categoriesFromFilter.forEach((categoryFromFilter) => {
            const categoriesRemaped: number[] = newCategoryIdToOldNskId[categoryFromFilter];

            if (categoriesRemaped && categoriesRemaped.length) {
                oldNskCategories.push(...categoriesRemaped.map((category) => category.toString()));
            }
        });
    }

    return oldNskCategories;
};