import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';
import { ESubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { mapQueryParamCategoryToOld } from 'modules/advertisementsFilter/query/mappers/mapQueryParamCategoryToOld';
import { ADVERTISEMENT_SEARCH_OLD } from 'modules/route/routes';

export const createUrlWithQueryParams = (
    category: (EMainCategory | ESubCategory)[] | EMainCategory | ESubCategory,
    withoutDomain?: boolean,
    isForDevProject?: boolean,
): string => {
    let categories: (EMainCategory | ESubCategory)[] = [];
    if (!Array.isArray(category)) {
        categories = [category];
    } else {
        categories = category;
    }

    const categoriesMapped = mapQueryParamCategoryToOld(categories);

    let queryParamsOldListing = '';
    const encodedParams = encodeURI('[categories][ids]');
    if (categoriesMapped && categoriesMapped.length) {
        queryParamsOldListing += `p${encodedParams}=${categoriesMapped.join('.')}`;
    }

    if (isForDevProject) {
        return queryParamsOldListing;
    }

    if (withoutDomain) {
        return `${ADVERTISEMENT_SEARCH_OLD}?${queryParamsOldListing}`;
    }

    return `
        ${AppConfigService.getNehnutelnostiUrl()}${ADVERTISEMENT_SEARCH_OLD}?${queryParamsOldListing}
    `;
};