import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { ESubCategory } from 'components/page/homePage/enums/categorySefs/ESubCategory';
import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';
import { ESubCategory as EApiSubCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/ESubCategory';
import { createUrlWithQueryParams } from 'modules/advertisementsFilter/query/creators/createUrlWithQueryParamsForHP';
import FamilyHouseLandIcon from 'modules/theme/components/icons/category/FamilyHouseLand.svg';
import CommercionalLandIcon from 'modules/theme/components/icons/category/CommercionalLand.svg';
import RecreationLandIcon from 'modules/theme/components/icons/category/RecreationLand.svg';
import VineyardsLandIcon from 'modules/theme/components/icons/category/VineyardsLand.svg';
import ForestLandIcon from 'modules/theme/components/icons/category/ForestLand.svg';
import GardenIcon from 'modules/theme/components/icons/category/Garden.svg';

export const landsIconBoxes = [
    {
        label: ESubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES,
        icon: FamilyHouseLandIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_LAND_FOR_FAMILY_HOUSES),
        sef: ESefCategories.SECONDARY_LAND_FOR_FAMILY_HOUSES,
        parentSef: ESefCategories.SECONDARY_LANDS,
    },
    {
        label: ESubCategory.SECONDARY_RECREATIONAL_LAND,
        icon: RecreationLandIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_RECREATIONAL_LAND}`,
        sef: ESefCategories.SECONDARY_RECREATIONAL_LAND,
        parentSef: ESefCategories.SECONDARY_LANDS,
    },
    {
        label: ESubCategory.SECONDARY_COMMERCIAL_PLOTS,
        icon: CommercionalLandIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_COMMERCIAL_PLOTS),
        sef: ESefCategories.SECONDARY_COMMERCIAL_PLOTS,
        parentSef: ESefCategories.SECONDARY_LANDS,
    },
    {
        label: ESubCategory.SECONDARY_GARDEN,
        icon: GardenIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_GARDEN}`,
        sef: ESefCategories.SECONDARY_GARDEN,
        parentSef: ESefCategories.SECONDARY_LANDS,
    },
    {
        label: ESubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
        icon: VineyardsLandIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS),
        sef: ESefCategories.SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS,
        parentSef: ESefCategories.SECONDARY_LANDS,
    },
    {
        label: ESubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
        icon: ForestLandIcon,
        link: createUrlWithQueryParams(EApiSubCategory.SECONDARY_AGRICULTURAL_AND_FOREST_LAND),
        sef: ESefCategories.SECONDARY_AGRICULTURAL_AND_FOREST_LAND,
        parentSef: ESefCategories.SECONDARY_LANDS,
    },
];