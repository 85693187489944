import { ESefCategories } from 'components/page/homePage/enums/categorySefs/ESefCategories';
import { ESubCategory } from 'components/page/homePage/enums/categorySefs/ESubCategory';
import StudioIcon from 'modules/theme/components/icons/category/Studio.svg';
import Room1Icon from 'modules/theme/components/icons/category/Room1.svg';
import Room2Icon from 'modules/theme/components/icons/category/Room2.svg';
import Room3Icon from 'modules/theme/components/icons/category/Room3.svg';
import Room4Icon from 'modules/theme/components/icons/category/Room4.svg';
import Room5Icon from 'modules/theme/components/icons/category/Room5.svg';
import MaisonetteIcon from 'modules/theme/components/icons/category/Maisonette.svg';
import HolidayApartmentIcon from 'modules/theme/components/icons/category/HolidayApartment.svg';
import LoftIcon from 'modules/theme/components/icons/category/Loft.svg';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

export const apartmentIconBoxes = [
    {
        label: ESubCategory.SECONDARY_STUDIO_APARTMENT,
        icon: StudioIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_STUDIO_APARTMENT}`,
        sef: ESefCategories.SECONDARY_STUDIO_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.ONE_ROOM_APARTMENT,
        icon: Room1Icon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.ONE_ROOM_APARTMENT}`,
        sef: ESefCategories.ONE_ROOM_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.TWO_ROOM_APARTMENT,
        icon: Room2Icon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.TWO_ROOM_APARTMENT}`,
        sef: ESefCategories.TWO_ROOM_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.SECONDARY_THREE_ROOM_APARTMENT,
        icon: Room3Icon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.SECONDARY_THREE_ROOM_APARTMENT}`,
        sef: ESefCategories.SECONDARY_THREE_ROOM_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.FOUR_ROOM_APARTMENT,
        icon: Room4Icon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.FOUR_ROOM_APARTMENT}`,
        sef: ESefCategories.FOUR_ROOM_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.FIVE_PLUS_ROOM_APARTMENT,
        icon: Room5Icon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.FIVE_PLUS_ROOM_APARTMENT}`,
        sef: ESefCategories.FIVE_PLUS_ROOM_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.MAISONETTE,
        icon: MaisonetteIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.MAISONETTE}`,
        sef: ESefCategories.MAISONETTE,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.HOLIDAY_APARTMENT,
        icon: HolidayApartmentIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.HOLIDAY_APARTMENT}`,
        sef: ESefCategories.HOLIDAY_APARTMENT,
        parentSef: ESefCategories.APARTMENTS,
    },
    {
        label: ESubCategory.LOFT,
        icon: LoftIcon,
        link: `${AppConfigService.getNehnutelnostiUrl()}/${ESefCategories.LOFT}`,
        sef: ESefCategories.LOFT,
        parentSef: ESefCategories.APARTMENTS,
    },
];