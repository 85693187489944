import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    AdvertisementsSearchFilter
} from 'components/advertisementsFilter/components/AdvertisementsSearchFilter';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import AdvertisementCountDisplay from 'components/page/homePage/components/filter/partials/content/partials/advertisementCount/AdvertisementCountDisplay';

interface IContent {
    baseFilterLocations: ILocation[];
    initialSearchFilterData: IInitialSearchFilterData;
}

export const Content: FC<IContent> = ({
    initialSearchFilterData,
}) => {
    // NOTE: prevent hidden faces in specific resolutions.
    const [, setWindowHeight] = useState(0);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setWindowHeight(window.innerHeight);
        }

        const handleWindowResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <Box
            top={{
                xs: 90,
                md: '40%',
                lg: '50%',
            }}
            left="50%"
            width="100%"
            display="flex"
            alignItems="center"
            position="absolute"
            flexDirection="column"
            sx={{
                transform: {
                    xs: 'translate(-50%, 0%)',
                    md: 'translate(-50%, -50%)',
                },
            }}
        >
            <Box
                mb={{ xs: 1, md: 2 }}
                maxWidth={{ xs: 300, sm: '100%' }}
                sx={{ textShadow: '0 0 4px rgba(0,0,0,0.4)' }}
            >
                <Text
                    center
                    semibold
                    variant="display5"
                    mobileVariant="h3"
                    color={(palette) => palette.labelPrimaryInverse}
                    component="h1"
                >
                    Vybrať správne je&nbsp;kľúčové
                </Text>
            </Box>
            <AdvertisementCountDisplay />
            <Box
                mt={2}
                width="100%"
                paddingX={4}
                display="flex"
                justifyContent="center"
            >
                <AdvertisementsSearchFilter
                    pagination={null}
                    filterDataFromUrl={null}
                    initialSearchFilterData={initialSearchFilterData}
                />
            </Box>
        </Box>
    );
};
